<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                受邀出席 Rust 开发者大会｜Rust 如何助力量化高频交易？
              </p>
              <div class="fst-italic mb-2">2022年7月31日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >Rust 开发者大会</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/11/01-封面.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    工欲善其事，必先利其器。面对时代变革、技术迭代，开发者们是如何使用Rust构建各场景应用的呢？受疫情影响，延期一年的“第二届中国Rust开发者大会”于7月31日在线上顺利举行，为Rust爱好者带来了前沿的技术交流和实践成果。
                  </p>
                   <p>
                    非凸科技量化策略负责人陆一洲受邀出席分论坛“Rust商业实践”，带来了“Rust如何助力量化高频交易”的主题演讲，为大家揭晓答案。
                  </p>
                   <p>
                    非凸科技以算法交易执行切入到量化交易领域，为券商、量化私募等众多大型金融机构提供优质的算法服务。公司基于Rust生态，打造了高效率、低延迟、高可靠、全内存的高频交易平台，相较于C++等其他语言，在安全性与稳定性上可以得到更好的保障，且随着量化交易规模的增大，优势也会更加突出。
                  </p>
                  <h4 class="my-4">一、量化交易</h4>
                  <p>
                    近年来，量化行业发展迅速，量化私募管理规模预计已突破1.6万亿元。在量化的竞技场中，技术的持续迭代，才是量化机构长期保持行业竞争力的保障。
                  </p>
                  <p>
                    量化私募，简而言之，是为市场提供流动性的同时寻找机会低买高卖。陆一洲表示，“在创收过程中有两大重点，一是期望收益，二是回撤。整体而言，期望回撤与收益主要取决于量化机构的综合水平，包括人才、商务、服务器等。而非凸科技作为一家精益求精的企业，致力于优化每一个细节。”
                  </p>
                  <p>
                    在量化交易中，有一部分回撤是在策略意料之外的，比如进程闪退、上下游出问题等。针对这些问题，Rust相对于传统的C++就是一个优秀的解决方案。那么，Rust对回撤有何影响？“首先，Rust自身系统稳定，常见的各种线程调度、内存管理等问题，几乎在编译阶段就能搞定；其次，Rust可以高效应对风险，量化实盘交易中由于系统的复杂性，难免会出现各种问题，那么应对各种风险时的速度是重要考量点。”陆一洲进一步解释道。
                  </p>
                  <h4 class="my-4">二、量化策略</h4>
                   <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/11/02-量化策略.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p >
                    量化世界千变万化，其相应的策略也星罗棋布，策略本身也随着市场起起落落。“如果要找到优秀的策略，要么拼运气，要么拼迭代策略的速度。显然，前者无可改变，后者才是我们的着力点。”陆一洲说道。
                  </p>
                  <p >
                    传统量化方案“Python研究员 + C++转写员”，策略迭代需要2-3天完成。陆一洲表示，“现实情况是，很多策略看似很厉害，模拟盘无敌，但上了实盘，表现却一般。可能存在各种问题，比如策略本身有误、转写有误、回测代码有误等。”
                  </p>
                  <p >
                    那么，能否一人全链路搞定？Python会存在速度太慢而无法上实盘的问题，而C++存在实验速度过慢、测试环境较宽松、优化细节较大等问题，相较之下，Rust以其方便的包管理、高稳定性以及相对较浅的细节深度等优势，获得青睐。陆一洲表示，“非凸科技内部设计了一套基于Rust的策略标准库，封装了大量的常用算法与工具，使得所有的策略都可以在百行内实现，并可同时上回测、模拟盘与实盘。”
                  </p>
                   <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/11/03-绩效表现.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p >
                    “全链路使用Rust，不仅可以在30分钟内完成一轮迭代，还可以耗时缩短50%，数据多训练300%。相较传统量化方案，非凸科技可以在一天内完成10+轮迭代，再配合强大的算力，我们正迅速赶超前者。”陆一洲补充道。非凸智能算法6月的绩效，相对Twap收益率为 7-8bp，而对比2021年10月C++框架平均收益率1-2bp，提升约400%。
                  </p>
                  <p >
                    Copy作为能够影响编译器行为的Trait，对于理解Rust基础有着重要作用，而Clone是开发者能够自定义复制行为的Trait。对此，陆一洲就“Clone 与 Copy”以及“GPU 分配小工具”做了代码讲解。
                  </p>
                  <h4 class="mb-4">三、人才招聘</h4>
                   <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/11/04-人才招聘.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p >
                    技术要迭代，策略要提升。百亿规模并非是量化私募的天花板，未来量化的竞争离不开人才的较量。如果说设备是硬件的地基，那么人才就是软件的核心要点，量化行业聚集了一批最聪明的人。
                  </p>
                  <p >
                    非凸科技在上海、北京、成都、新加坡、美国均设置了办公室，以及开放了社招/校招岗位。陆一洲表示，“为了招到优秀的人才，我们在保证客户业绩稳定、软硬件持续投入的同时，也给出了同行业最好的个体成长和发展路径，匹配了舒适的工作与创新环境，还有和天才同事一起工作的快乐。除此之外，我们的激励和分配机制也非常合理，更多核心岗位向长期想和公司共同成长的奋斗者开放！”
                  </p>
                  <p >
                    “从笔试到面试的过程中，要满足三个前提条件：一是对入职有较强意愿，二是对新事物有热情，三是有较强的学习能力。这样再通过笔试中的算法/工程细节，我们就能更精准地筛选出与之相匹配的候选人了。”陆一洲补充道。
                  </p>
                  <p >
                    在人才培养方面，绕不开Review代码。陆一洲说道，“相较C++，Rust完美的编译器、各种风格类Warning 以及Formatter插件，可以帮你做好代码规范。于是，策略研究员就有机会直接上实盘验证代码，清晰地感受自己写的每一行代码对实盘造成的影响，从而高效地完成自我迭代。”
                  </p>
                  <p >
                    本次技术分享会圆满结束，期间收获很多点赞和讨论。在QA环节，陆一洲针对大家的提问，一一做出了解答。
                  </p>
                  <p >
                    欢迎大家加入非凸科技，基于高效稳健的Rust，创造出更高的价值！
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News10",
};
</script>

<style></style>
